<script setup lang="ts">
import type {Package} from "~/composables/tebex";

const {t} = useI18n()
const {locale} = useI18n()

const props = defineProps<{
  package: Package
  packages: Record<number, Package>
}>()

const discounted = computed(() => !!props.package.discount)
const formatter = computed(() => new Intl.NumberFormat(locale.value || "de-DE", {style: 'percent'}))
</script>

<template>
  <div class="flex flex-col rounded overflow-hidden bg-secondary">
    <div v-show="discounted" class="bg-primary text-white p-3 text-center text-xl w-full">
      <span
        :title="t('jc.component.tebexPackage.discount.title', { discount: formatter.format(props.package.discount / (props.package.discount + props.package.base_price)) })"
      >
        {{ t('jc.component.tebexPackage.discount.text', { discount: formatter.format(props.package.discount / (props.package.discount + props.package.base_price)) }) }}
      </span>
    </div>
    <div class="flex flex-col p-8">
      <div class="w-full aspect-square">
        <NuxtPicture
          :src="props.package.image?? '/images/view/shop/product_placeholder.png'"
          :alt="t('jc.component.tebexPackage.image.alt')"
          width="2000px"
          sizes="540px mobile:248px tablet:246px laptop:286px"
          placeholder="/images/view/shop/product_placeholder.png"
          preload
        />
      </div>
      <span class="text-center text-white text-2xl">
        {{t('jc.component.tebexPackage.amount', {amount: (props.package.meta?.amount ?? 0) - (props.package.meta?.bonus ?? 0)}) }}</span>
      <span class="text-center text-white mt-2 mb-10">
        {{ t('jc.component.tebexPackage.bonus', {bonus: props.package.meta?.bonus ?? 0}) }}
      </span>
      <TebexButton :package="props.package" :packages="props.packages"/>
    </div>
  </div>
</template>
