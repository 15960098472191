import type {
  Basket,
  Data,
  Message,
  Package, PackageMeta
} from "./types";

export const LocalStorageBasketKey = "tebex-basket"
export const StateBasketKey = "tebex-basket"

export const UrlQueryKey = "tebex"
export enum UrlQueryValue {
  Completed = "completed",
  Canceled = "cancel",
}

function parsePackageMeta<T extends { description: string }>(pack: T): T & { meta: PackageMeta } {
  let meta = null
  try {
    meta = JSON.parse(
      pack
        .description
        // description might be in a paragraph, e.g. "<p>{"foo": 1}</p>"
        .replaceAll(/(<(\/)?p>)/g, "")
    )
  } catch (e) {}
  return {
    ...pack,
    meta: meta,
  }
}

function parseBasketMeta(basket: Basket): Basket {
  return {
    ...basket,
    packages: basket.packages
      .map(pack => ({ ...pack, ...parsePackageMeta(pack) }))
  }
}

export function useTebex() {
  const config = useRuntimeConfig()
  const webstoreId = config.public.tebexWebstoreId
  const shopUrl = config.public.shopUrl

  // global state (request scoped)
  const basket = useState<Basket | null>(StateBasketKey, () => null)
  const basketSize = computed(() =>
    basket.value
      ?.packages
      ?.map(item => item.in_basket.quantity)
      ?.reduce((c, a) => c + a, 0)
    ?? 0
  )

  // sync basket with client localstorage
  watch(basket, (value, _) => {
    if (process.server) {
      console.warn("cannot save tebex basket on server")
      return
    } else if (value?.ident) {
      localStorage.setItem(LocalStorageBasketKey, value?.ident)
    } else {
      localStorage.removeItem(LocalStorageBasketKey)
    }
  })

  // base fetcher for tebex
  const tebex = $fetch.create({
    baseURL: "https://headless.tebex.io",
  })

  // basket
  const createBasket = async (username: string) => {
    return tebex<Data<Basket>>(
      `/api/accounts/${webstoreId}/baskets`,
      { method: "POST", body: {
          username: username,
          // urls, might be unnecessary
          complete_url: shopUrl + `?${UrlQueryKey}=${UrlQueryValue.Completed}`,
          cancel_url: shopUrl + `?${UrlQueryKey}=${UrlQueryValue.Canceled}`,
          complete_auto_redirect: false,
        }}
    )
      .then(fetched => parseBasketMeta(fetched.data))
  }
  const createSubscriptionBasket = async (username: string, packageId: number, variableData: object = {}) => {
    return createBasket(username)
      .then(fetched => tebex<Data<Basket>>(
        `/api/baskets/${fetched.ident}/packages`,
        { method: "POST", body: {
            package_id: packageId,
            quantity: 1,
            type: "subscription",
            variable_data: variableData,
          }}
      ))
      .then(fetched => parseBasketMeta(fetched.data))
  }
  const createGlobalBasket = async (username: string) => {
    return createBasket(username)
      .then(fetched => {
        basket.value = parseBasketMeta(fetched)
      })
  }
  const loadBasket = async () => {
    const basketIdent = localStorage.getItem(LocalStorageBasketKey)
    if (basketIdent) {
      await tebex<Data<Basket>>(`/api/accounts/${webstoreId}/baskets/${basketIdent}`)
        .then(fetched => {
          if (!fetched.data.complete) {
            basket.value = parseBasketMeta(fetched.data)
          }
        })
        // catch all, just use new basket
        .catch(_ => {})
    }
  }
  const abandonBasket = async () => {
    basket.value = null
  }

  // basket packages
  const addPackage = async (packageId: number, quantity: number = 1, variableData: object = {}) => {
    return tebex<Data<Basket>>(
      `/api/baskets/${basket.value?.ident}/packages`,
      { method: "POST", body: {
          package_id: packageId,
          quantity: quantity,
          type: "single",
          variable_data: variableData,
        }}
    )
      .then(fetched => {
        basket.value = parseBasketMeta(fetched.data)
      })
  }
  const removePackage = async (packageId: number) => {
    return tebex<Data<Basket>>(
      `/api/baskets/${basket.value?.ident}/packages/remove`,
      { method: "POST", body: { package_id: packageId }}
    )
      .then(fetched => {
        basket.value = parseBasketMeta(fetched.data)
      })
  }
  const updatePackage = async (packageId: number, quantity: number) => {
    return tebex<Data<Basket>>(
      `/api/baskets/${basket.value?.ident}/packages/${packageId}`,
      { method: "PUT", body: { quantity: quantity }}
    )
      .then(fetched => {
        basket.value = parseBasketMeta(fetched.data)
      })
  }

  // basket creator codes
  const applyCreatorCode = async (code: string) => {
    return tebex<Message>(
      `/api/accounts/${webstoreId}/baskets/${basket.value?.ident}/creator-codes`,
      { method: "POST", body: { creator_code: code }}
    ).then(() => {})
  }
  const removeCreatorCode = async () => {
    return tebex<Message>(
      `/api/accounts/${webstoreId}/baskets/${basket.value?.ident}/creator-codes/remove`,
      { method: "POST" }
    ).then(() => {})
  }

  // basket gift cards
  const applyGiftCard = async (code: string) => {
    return tebex<Message>(
      `/api/accounts/${webstoreId}/baskets/${basket.value?.ident}/giftcards`,
      { method: "POST", body: { card_number: code }}
    ).then(() => {})
  }
  const removeGiftCard = async (code: string) => {
    return tebex<Message>(
      `/api/accounts/${webstoreId}/baskets/${basket.value?.ident}/giftcards/remove`,
      { method: "POST", body: { card_number: code }}
    ).then(() => {})
  }

  // basket coupons
  const applyCoupon = async (code: string) => {
    return tebex<Message>(
      `/api/accounts/${webstoreId}/baskets/${basket.value?.ident}/coupons`,
      { method: "POST", body: { coupon_number: code }}
    ).then(() => {})
  }
  const removeCoupon = async (code: string) => {
    return tebex<Message>(
      `/api/accounts/${webstoreId}/baskets/${basket.value?.ident}/coupons/remove`,
      { method: "POST", body: { coupon_number: code }}
    ).then(() => {})
  }

  // packages
  const getPackages = async (ident: string | null) => {
    return tebex<Data<Package[]>>(
      `/api/accounts/${webstoreId}/packages`,
      { method: "GET", query: { basketIdent: ident } }
    )
      .then(data => {
        return data.data
          // decode meta fields (custom data)
          .map(pack => parsePackageMeta(pack))
      })
      .then(data => {
        return Object.fromEntries(data.map(item => ([item.id, item])))
      })
  }

  return {
    tebex,
    getPackages,
    createBasket,
    createSubscriptionBasket,
    createGlobalBasket,
    abandonBasket,
    loadBasket,
    addPackage,
    removePackage,
    updatePackage,
    applyCreatorCode,
    removeCreatorCode,
    applyGiftCard,
    removeGiftCard,
    applyCoupon,
    removeCoupon,
    basketSize,
    // readonly basket
    basket: computed(() => basket.value),
  }
}
