<script setup lang="ts">
import type {Basket, Package} from "~/composables/tebex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const { t } = useI18n()
const { addPackage, createSubscriptionBasket, basket } = useTebex()
const { locale } = useI18n()

const props = defineProps<{
  package: Package
  packages: Record<number, Package>
}>()

// true if the tebex login should be shown
const login = ref<boolean>(false)
// the package type that was clicked, used for login and showing loading animation
const pending = ref<'single' | 'subscription' | null>(null)
// the ephemeral basket for a subscription
const subscription = ref<Basket | null>(null)

// called on login success to add the previously clicked package type (see 'pending')
async function retry() {
  login.value = false
  if (!pending.value) {
    return
  }
  await add(pending.value)
}

// cancel any login and pending package add
async function cancel() {
  login.value = false
  pending.value = null
}

// tries to add a package to the basket, opens login if basket is missing
async function add(type: 'single' | 'subscription') {
  pending.value = type
  if (!basket.value) {
    login.value = true
    return
  }

  // handle subscription by creating new basket with only the subscription
  if (type == "subscription") {
    try {
      subscription.value = await createSubscriptionBasket(basket.value.username, props.package.id)
    } finally {
      pending.value = null
    }
    return
  }

  await addPackage(props.package.id, 1)
    .finally(() => { pending.value = null })
}

const limitReached = computed(() => props.package.disable_quantity && basket.value?.packages.findIndex(item => item.id == props.package.id) !== -1)

const formatter = computed(() => new Intl.NumberFormat(locale.value || "de-DE", { style: 'currency', currency: basket.value?.currency ?? "EUR" }))
</script>

<template>
  <div>
    <TebexLogin
      :open="login"
      @done="() => retry()"
      @cancel="() => cancel()"
    />

    <TebexSubscriptionBasket
      :open="subscription != null"
      :basket="subscription"
      :packages="packages"
      @done="() => subscription = null"
    />

    <div class="bg-secondary flex flex-col gap-2">
      <div class="flex flex-row gap-2 text-white">
        <span>{{ t(`jc.tebex.category.${packages?.[props.package.id]?.category?.id ?? 'fallback'}.packageName`, { amount: props.package.meta?.amount ?? 0 }) }}</span>
        <span class="ml-auto line-through">{{ props.package.discount ? formatter.format(props.package.base_price + props.package.discount) : '' }}</span>
        <span class="font-semibold">{{ formatter.format(props.package.total_price) }}</span>
      </div>
      <button
        v-if="props.package.type == 'single' || props.package.type == 'both'"
        type="submit"
        @click="() => add('single')"
        :disabled="limitReached"
        class="bg-primary hover:bg-primary-highlighted active:bg-primary-selected disabled:opacity-50 px-3 py-2 rounded text-white flex flex-row gap-4 justify-between group"
        :title="t('jc.component.tebexButton.addButton.title')"
      >
        <font-awesome-icon
          icon="cart-shopping-fast"
          class="text-[1.2em] animate-bounce-once group-active:animate-none"
        />
        <span>{{ t('jc.component.tebexButton.addButton.label') }}</span>
      </button>
      <button
        v-if="props.package.type == 'subscription' || props.package.type == 'both'"
        type="submit"
        @click="() => add('subscription')"
        :disabled="pending == 'subscription'"
        class="bg-white hover:gray-50 active:gray-100 disabled:opacity-50 px-3 py-2 rounded text-primary hover:text-primary-highlighted active:text-primary-selected flex flex-row gap-4 justify-between"
        :title="t('jc.component.tebexButton.subscribeButton.title')"
      >
        <font-awesome-icon
          icon="arrows-rotate"
          :spin="pending == 'subscription'"
          class="text-[1.2em]"
        />
        <span>{{ t('jc.component.tebexButton.subscribeButton.label') }}</span>
      </button>
    </div>
  </div>
</template>
