<script setup lang="ts">
import type {Basket, Package} from "~/composables/tebex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const { t } = useI18n()

const props = defineProps<{
  open: boolean
  basket: Basket | null
  packages: Record<number, Package>
}>()

const emit = defineEmits<{
  (e: 'done', value: void): void
}>()

function done(wait: number = 0) {
    setTimeout(() => emit("done"), wait)
}
</script>

<template>
  <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <Transition name="backdrop">
      <div v-show="props.open" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    </Transition>

    <Transition name="modal">
      <div v-show="props.open" class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          @click.self="() => done()"
          class="flex min-h-full items-end justify-center p-4 text-center mobile:items-center"
          :title="t('jc.component.tebexSubscriptionBasket.closeHint')"
        >
          <div class="relative transform overflow-hidden rounded-lg bg-secondary text-left shadow-xl transition-all mobile:my-8 w-full mobile:max-w-lg">
            <div class="bg-secondary px-4 pb-4 pt-5 mobile:p-6 mobile:pb-4">
              <div class="mt-3 text-center mobile:ml-4 mobile:mt-0 mobile:text-left flex flex-col justify-center items-center">
                <h3 class="text-xl text-white font-semibold leading-6" id="modal-title">
                  {{ t('jc.component.tebexSubscriptionBasket.heading') }}
                </h3>
                <span class="text-lg text-white mt-3">
                  {{ t(`jc.tebex.category.${props.packages?.[props.basket?.packages?.[0]?.id ?? 0]?.category?.id ?? 'fallback'}.packageName`, { amount: props.basket?.packages?.[0]?.meta?.amount ?? 0 }) }}
                </span>
                <p class="text-center mt-2">{{ t('jc.component.tebexSubscriptionBasket.text') }}</p>
              </div>
            </div>
            <div class="px-4 py-3 pb-6 mobile:flex mobile:flex-row-reverse mobile:px-6 mobile:justify-center">
              <a
                target="_blank"
                :href="props.basket?.links?.checkout ?? ''"
                @click="() => done(300)"
                type="button"
                class="inline-flex gap-2 w-full justify-center rounded bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-highlighted active:bg-primary-selected disabled:opacity-50 mobile:ml-3 mobile:w-auto"
                :title="t('jc.component.tebexSubscriptionBasket.checkoutButton.title')"
              >
                <font-awesome-icon
                  icon="cart-shopping-fast"
                  class="my-auto mr-2"
                />
                <span>{{ t('jc.component.tebexSubscriptionBasket.checkoutButton.label') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
.backdrop-enter-active {
  @apply transition-opacity ease-out duration-300
}

.backdrop-leave-active {
  @apply transition-opacity ease-in duration-200
}

.backdrop-enter-from {
  @apply opacity-0
}

.backdrop-enter-to {
  @apply opacity-100
}

.backdrop-leave-from {
  @apply opacity-100
}

.backdrop-leave-to {
  @apply opacity-0
}

.modal-enter-active {
  @apply transition-all ease-out duration-300
}

.modal-leave-active {
  @apply transition-all ease-in duration-200
}

.modal-enter-from {
  @apply opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95
}

.modal-enter-to {
  @apply opacity-100 translate-y-0 mobile:scale-100
}

.modal-leave-from {
  @apply opacity-100 translate-y-0 mobile:scale-100
}

.modal-leave-to {
  @apply opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95
}
</style>
