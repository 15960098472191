<script setup lang="ts">
import {UrlQueryKey, UrlQueryValue} from "~/composables/tebex";
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from "@fortawesome/vue-fontawesome";

const {t} = useI18n()
const config = useRuntimeConfig()
const localePath = useLocalePath()
const route = useRoute()
const {abandonBasket, loadBasket, getPackages, createBasket, basket, basketSize} = useTebex()

definePageMeta({
  nameKey: 'shop'
})

useHead(() => {
  // define the meta image
  const imageUrl = "/images/view/shop/meta_preview.png"

  // assemble the meta information
  return {
    meta: [
      // open graph
      {name: 'og:title', content: t(`jc.head.shop.title.meta`)},
      {name: 'og:image', content: imageUrl},
      // twitter card
      {name: 'twitter:image', content: imageUrl},
      {name: 'twitter:title', content: t(`jc.head.shop.title.meta`)},
    ]
  }
})

// get packages
const {data: packages} = useAsyncData(
  'tebex-packages',
  async () => {
    let ident = basket.value?.ident
    if (!ident) {
      // use ephemeral basket ident to get discounts (thank you tebex headless ui)
      ident = (await createBasket("Scrayos"))?.ident
    }
    return getPackages(ident)
  },
  {
    server: true,
    lazy: true,
    watch: [() => basket.value?.ident]
  }
)

// handle basket (load, completed and canceled)
onMounted(() => {
  const status = route.query[UrlQueryKey]
  switch (status) {
    case UrlQueryValue.Completed:
      abandonBasket()
      break
    default:
      loadBasket()
      break
  }
})

const premiumCategory = computed(() => Object.entries(packages.value ?? {})
  .map(([_, item]) => item)
  .filter(item => item.category.id === config.public.tebexCategoryPremium)
  .sort((a, b) => (a.meta?.order ?? 0) - (b.meta?.order ?? 0))
)

const crystalCategory = computed(() => Object.entries(packages.value ?? {})
  .map(([_, item]) => item)
  .filter(item => item.category.id === config.public.tebexCategoryCrystals)
  .sort((a, b) => (a.meta?.order ?? 0) - (b.meta?.order ?? 0))
)

const premiumBenefits = [
  "levelPass",
  "cosmeticHat",
  "guaranteedSlot"
]

const crystalBenefits = [
  {
    icon: 'flask-round-potion',
    title: t('jc.view.shop.crystals.icon.boost.title'),
    text: t('jc.view.shop.crystals.icon.boost.text')
  },
  {
    icon: 'hat-wizard',
    title: t('jc.view.shop.crystals.icon.hat.title'),
    text: t('jc.view.shop.crystals.icon.hat.text')
  },
  {
    icon: 'sparkles',
    title: t('jc.view.shop.crystals.icon.other.title'),
    text: t('jc.view.shop.crystals.icon.other.text')
  }
]

const open = ref(false)
</script>

<template>
  <client-only>
    <div id="shop-basket" class="absolute">
      <teleport to="#navbar-basket">
        <button
          :key="basketSize"
          class="block p-2.5 bg-secondary hover:bg-secondary-highlighted text-white active:bg-secondary-selected rounded"
          @click="() => open = true"
          :title="t('jc.view.shop.basketButton.title')"
        >
          <span class="sr-only">{{ t('jc.view.shop.basketButton.label', {size: basketSize}) }}</span>
          <FontAwesomeLayers fixed-width>
            <FontAwesomeIcon
              transform="grow-3"
              icon="cart-shopping-fast"
              class="animate-bounce-once"
            />
            <FontAwesomeLayersText
              counter
              transform="shrink-1 down-32 right-32"
              :value="basketSize"
            />
          </FontAwesomeLayers>
        </button>
      </teleport>

      <button
        :key="basketSize"
        class="fixed hidden laptop:block right-5 laptop:right-10 z-10 p-2.5 bg-secondary hover:bg-secondary-highlighted text-white active:bg-secondary-selected rounded"
        @click="() => open = true"
        :title="t('jc.view.shop.basketButton.title')"
      >
        <span class="sr-only">{{ t('jc.view.shop.basketButton.label', {size: basketSize}) }}</span>
        <FontAwesomeLayers fixed-width>
          <FontAwesomeIcon
            transform="grow-3"
            icon="cart-shopping-fast"
            class="animate-bounce-once"
          />
          <FontAwesomeLayersText
            counter
            transform="shrink-1 down-32 right-32"
            :value="basketSize"
          />
        </FontAwesomeLayers>
      </button>
      <TebexBasket v-if="packages" :packages="packages" :open="open" @close="() => open = false"/>
    </div>
  </client-only>

  <Container
    id="heading"
  >
    <HeroCard
      :heading="t('jc.view.shop.hero.title')"
      :paragraph="t('jc.view.shop.hero.text')"
      :image="{
        src: 'images/view/shop/hero_banner.png',
        alt: t('jc.view.shop.hero.banner.alt'),
      }"
      :float="{
        src: 'images/view/shop/hero_float.png',
        alt: t('jc.view.shop.hero.float.alt'),
      }"
      :label="t('jc.view.shop.hero.button.label')"
      :icon="{ prefix: 'fas', iconName: 'link' }"
      :to="localePath({ name: 'legal-terms' })"
    />
  </Container>

  <time-only :start="new Date('2023-06-17')" :end="new Date('2023-07-01')">
    <Container>
      <HeaderCard
        :image="{
        src: 'images/view/shop/sale_background.png',
        alt: t('jc.view.shop.sale.background.alt')
      }"
      >
        <template #header>
          <h2 class="m-auto">{{ t('jc.view.shop.sale.text') }}</h2>
        </template>
      </HeaderCard>
    </Container>
  </time-only>

  <Container id="premium">
    <HeaderCard
      :image="{
        src: 'images/view/shop/premium_background.png',
        alt: t('jc.view.shop.premium.background.alt')
      }"
      divider
    >
      <template #header>
        <h2 class="mt-auto h1">{{ t('jc.view.shop.premium.heading') }}</h2>
      </template>

      <div class="flex flex-col">
        <p class="text-gray-900 mx-8 laptop:mx-16 mb-4 mt-8">{{ t('jc.view.shop.premium.text') }}</p>
        <div
          class="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-x-4 gap-y-16 mx-8 laptop:mx-16 mb-4 mt-8">
          <template
            v-for="benefit in premiumBenefits"
          >
            <div class="flex flex-col gap-2">
              <NuxtPicture
                :src="`images/view/shop/premium_benefit/${benefit}.png`"
                :alt="t(`jc.view.shop.premium.${benefit}.image.alt`)"
                loading="lazy"
                sizes="540px tablet:279px laptop:266px desktop:306px"
                class="rounded overflow-hidden"
                :img-attrs="{
                'class': 'rounded'
              }"
              />
              <h3 class="text-gray-900">{{ t(`jc.view.shop.premium.${benefit}.heading`) }}</h3>
              <p class="text-gray-900">{{ t(`jc.view.shop.premium.${benefit}.text`) }}</p>
            </div>
          </template>
        </div>
        <div class="bg-secondary mt-8">
          <div
            class="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-x-12 gap-y-8 mx-8 laptop:mx-16 mb-8 mt-8">
            <template v-for="item in premiumCategory" :key="item.id">
              <TebexButton v-if="packages" :packages="packages" :package="item"/>
            </template>
          </div>
        </div>
      </div>
    </HeaderCard>
  </Container>

  <Container id="crystals">
    <HeaderCard
      :image="{
        src: 'images/view/shop/crystals_background.png',
        alt: t('jc.view.shop.crystals.background.alt')
      }"
      divider
    >
      <template #header>
        <h2 class="mt-auto h1">{{ t('jc.view.shop.crystals.heading') }}</h2>
      </template>

      <div class="grid grid-cols-1 laptop:grid-cols-[5fr_3fr] gap-8 mx-8 laptop:mx-16 mb-8 mt-8">
        <IconSection
          :sections="crystalBenefits"
        />
        <NuxtPicture
          :src="'images/view/shop/crystals_float.png'"
          :alt="t('jc.view.shop.crystals.float.alt')"
          loading="lazy"
          sizes="640px laptop:960px desktop:1078px"
          class="hidden laptop:block m-auto"
        />
      </div>
    </HeaderCard>
  </Container>

  <Container id="currency">
    <div class="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-4">
      <template v-for="item in crystalCategory" :key="item.id">
        <TebexPackage v-if="packages" :packages="packages" :package="item"/>
      </template>
    </div>
  </Container>
</template>
