<template>
  <div class="relative z-20" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <Transition name="backdrop">
      <div v-show="props.open" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    </Transition>
    <Transition name="panel">
      <div v-show="props.open" class="fixed inset-0 overflow-hidden">
        <div @click.self="close" class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="flex h-full flex-col bg-secondary text-white py-6 shadow-xl">
                <div class="px-4 mobile:px-6 flex flex-row gap-4">
                  <client-only>
                    <button
                      class="relative bg-white rounded-full h-[2em] w-[2em] flex items-center justify-center overflow-hidden group"
                      :disabled="!basket"
                      @click="abandonBasket"
                      :title="t('jc.component.tebexBasket.abandonButton.title')"
                    >
                      <font-awesome-icon
                        v-if="!basket"
                        icon="user"
                        class="m-auto text-gray-900 text-[1.2em]"
                      />
                      <template v-else>
                        <div class="absolute inset-0 bg-white hidden group-hover:flex">
                          <font-awesome-icon
                            icon="xmark"
                            class="m-auto text-gray-900 text-[1.2em]"
                          />
                        </div>
                        <client-only>
                          <NuxtPicture
                            :src="avatarSrc || ''"
                            sizes="128px"
                            :alt="t('jc.component.tebexBasket.userHead.title', { username: basket.username })"
                          />
                        </client-only>
                      </template>
                    </button>
                    <h3 class="my-auto font-medium leading-6" id="slide-over-title">
                      {{ t(basket ? 'jc.component.tebexBasket.heading.withUsername' : 'jc.component.tebexBasket.heading', { username: basket?.username ?? '' }) }}
                    </h3>
                    <Transition name="button">
                      <button
                        @click="close"
                        type="button"
                        class="my-auto ml-auto rounded-full aspect-square p-1"
                        :title="t('jc.component.tebexBasket.closeButton.title')"
                      >
                        <span class="sr-only">{{ t('jc.component.tebexBasket.closeButton.label') }}</span>
                        <font-awesome-icon
                          icon="xmark"
                          class="text-[1.2em]"
                        />
                      </button>
                    </Transition>
                  </client-only>
                </div>
                <div class="relative mt-6 flex-1 px-4 mobile:px-6 flex flex-col gap-6 overflow-y-auto">
                  <client-only>
                    <div class="flex flex-col gap-4 overflow-y-auto overflow-x-hidden">
                      <template v-for="item in basket?.packages ?? []" :key="item.id">
                        <template v-for="i in item.in_basket.quantity" :key="`${item.id}-${i}`">
                          <div class="bg-white text-gray-900 rounded p-4">
                            <div class="flex flex-row gap-2">
                              <span class="font-semibold">{{ t(`jc.tebex.category.${packages?.[item.id]?.category?.id ?? 'fallback'}.packageName`, { amount: item.meta?.amount ?? 0 }) }}</span>
                              <button
                                @click="() => remove(item, i)"
                                :disabled="pending[`${item.id}-${i}`]"
                                type="button"
                                class="ml-auto rounded-full aspect-square p-1 disabled:opacity-50 h-[2em] w-[2em]"
                                :title="t('jc.component.tebexBasket.removeButton.title')"
                              >
                                <span class="sr-only">{{ t('jc.component.tebexBasket.removeButton.label') }}</span>
                                <font-awesome-icon
                                  :icon="pending[`${item.id}-${i}`] ? 'spinner-third' : 'xmark'"
                                  :spin="pending[`${item.id}-${i}`]"
                                  class="text-[1.2em]"
                                />
                              </button>
                            </div>
                            <span class="font-light inline-block mt-4">{{ formatter.format(item.in_basket.price) }}</span>
                          </div>
                        </template>
                      </template>
                    </div>

                    <div class="mt-auto flex flex-row gap-4">
                      <span>{{ t('jc.component.tebexBasket.basketSize', { size: basketSize }) }}</span>
                      <span class="ml-auto font-semibold text-lg">{{ formatter.format(basket?.total_price ?? 0) }}</span>
                    </div>
                    <div class="flex flex-col gap-2 mt-2">
                      <NuxtLink
                        class="bg-primary rounded text-white px-3 py-2 flex flex-row gap-2 aria-disabled:opacity-50 w-full"
                        :aria-disabled="!basket?.links?.checkout"
                        :to="basket?.links?.checkout ?? undefined"
                        :external="true"
                        :title="t('jc.component.tebexBasket.checkoutButton.title')"
                      >
                        <font-awesome-icon
                          icon="cart-shopping-fast"
                          class="inline-block my-auto"
                        />
                        <span class="ml-auto text-lg font-semibold">{{ t('jc.component.tebexBasket.checkoutButton.label') }}</span>
                      </NuxtLink>
                    </div>
                  </client-only>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import type {Package, PackageInBasket} from "~/composables/tebex";

const { t } = useI18n()
const { locale } = useI18n()
const { basket, removePackage, abandonBasket, updatePackage, basketSize } = useTebex()
const { avatarUrl } = useCrafatar()

const props = defineProps<{
  open: boolean
  packages: Record<number, Package>
}>()

const emit = defineEmits<{
  (e: "close", value: void): void
}>()

const pending = ref<{[key: string]: boolean}>({})

const formatter = computed(() => new Intl.NumberFormat(locale.value || "de-DE", { style: 'currency', currency: basket.value?.currency ?? "EUR" }))
const avatarSrc = computed(() => avatarUrl(basket.value?.username_id, { overlay: true }))

function close() {
  emit("close")
}

async function remove(item: PackageInBasket, index : number) {
  const key = `${item.id}-${index}`
  try {
    pending.value[key] = true
    if (item.in_basket.quantity > 1) {
      await updatePackage(item.id, item.in_basket.quantity - 1)
    } else {
      await removePackage(item.id)
    }
  } finally {
    delete pending.value[key]
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
.backdrop-enter-active {
  @apply transition-opacity ease-in-out duration-500
}

.backdrop-enter-from {
  @apply opacity-0
}

.backdrop-enter-to {
  @apply opacity-100
}

.backdrop-leave-active {
  @apply transition-opacity ease-in-out duration-500
}

.backdrop-leave-from {
  @apply opacity-100
}

.backdrop-leave-to {
  @apply opacity-0
}

.panel-enter-active {
  @apply transform transition ease-in-out duration-500 mobile:duration-700
}

.panel-enter-from {
  @apply translate-x-full
}

.panel-enter-to {
  @apply translate-x-0
}

.panel-leave-active {
  @apply transform transition ease-in-out duration-500 mobile:duration-700
}

.panel-leave-from {
  @apply translate-x-0
}

.panel-leave-to {
  @apply translate-x-full
}

.button-enter-active {
  @apply ease-in-out duration-500
}

.button-enter-from {
  @apply opacity-0
}

.button-enter-to {
  @apply opacity-100
}

.button-leave-active {
  @apply ease-in-out duration-500
}

.button-leave-from {
  @apply opacity-100
}

.button-leave-to {
  @apply opacity-0
}
</style>
