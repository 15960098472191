<template>
  <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <Transition name="backdrop">
      <div v-show="props.open" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    </Transition>

    <Transition name="modal">
      <div v-show="props.open" class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          @click.self="cancel"
          class="flex min-h-full items-end justify-center p-4 text-center mobile:items-center"
          :title="t('jc.component.tebexLogin.closeHint')"
        >
          <form
            @submit.prevent="login"
            novalidate
            class="relative transform overflow-hidden rounded-lg bg-secondary text-left shadow-xl transition-all mobile:my-8 w-full mobile:max-w-lg"
          >
            <div class="bg-secondary px-4 pb-4 pt-5 mobile:p-6 mobile:pb-4">
              <div class="mt-3 text-center mobile:ml-4 mobile:mt-0 mobile:text-left flex flex-col justify-center items-center">
                <h3 class="text-lg text-white font-semibold leading-6" id="modal-title">{{ t('jc.component.tebexLogin.heading') }}</h3>
                <label class="mt-4 w-full flex flex-col justify-center">
                  <span class="text-center text-white text-xs">{{ t('jc.component.tebexLogin.usernameInput.label') }}</span>
                  <input
                    :placeholder="t('jc.component.tebexLogin.usernameInput.placeholder')"
                    type="text"
                    v-model="username"
                    class="mt-1 mx-8 block rounded text-gray-900 invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 peer"
                    pattern="[a-zA-Z0-9_]{2,15}"
                    required
                  >
                  <span class="mt-2 mx-8 hidden text-xs text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                    {{ t('jc.component.tebexLogin.usernameInput.invalid') }}
                  </span>
                </label>
              </div>
            </div>
            <div class="px-4 py-3 mobile:flex mobile:flex-row-reverse mobile:px-6 mobile:justify-center">
              <button
                :disabled="pending || !username.match(/^[a-zA-Z0-9_]{2,16}$/)"
                @click="login"
                type="submit"
                class="inline-flex gap-2 w-full justify-center rounded bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-highlighted active:bg-primary-selected disabled:opacity-50 mobile:ml-3 mobile:w-auto"
                :title="t('jc.component.tebexLogin.confirmButton.title')"
              >
                <font-awesome-icon
                  v-if="pending"
                  icon="spinner-third"
                  spin
                  class="my-auto mr-2"
                />
                <span>{{ t('jc.component.tebexLogin.confirmButton.label') }}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useToast} from "vue-toastification";

const { t } = useI18n()
const { abandonBasket, createGlobalBasket } = useTebex()
const toast = useToast()

const props = defineProps<{
  open: boolean
}>()

const emit = defineEmits<{
  (e: "done", value: void): void
  (e: "cancel", value: void): void
}>()

const username = ref("")
const pending = ref(false)

function cancel() {
  emit("cancel")
  username.value = ""
}

async function login() {
  pending.value = true
  try {
    await abandonBasket()
    await createGlobalBasket(username.value)
    emit("done")
  } catch (e) {
    toast.error(t('jc.component.tebexLogin.failed'))
  } finally {
    pending.value = false
    username.value = ""
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
.backdrop-enter-active {
  @apply transition-opacity ease-out duration-300
}

.backdrop-leave-active {
  @apply transition-opacity ease-in duration-200
}

.backdrop-enter-from {
  @apply opacity-0
}

.backdrop-enter-to {
  @apply opacity-100
}

.backdrop-leave-from {
  @apply opacity-100
}

.backdrop-leave-to {
  @apply opacity-0
}

.modal-enter-active {
  @apply transition-all ease-out duration-300
}

.modal-leave-active {
  @apply transition-all ease-in duration-200
}

.modal-enter-from {
  @apply opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95
}

.modal-enter-to {
  @apply opacity-100 translate-y-0 mobile:scale-100
}

.modal-leave-from {
  @apply opacity-100 translate-y-0 mobile:scale-100
}

.modal-leave-to {
  @apply opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95
}
</style>
