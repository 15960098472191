<script setup lang="ts">
const props = defineProps<{
  start?: Date
  end?: Date
}>()

const now = ref(new Date())

onMounted(() => {
  // check once per minute
  setInterval(() => {
    now.value = new Date()
  }, 60 * 1000)
})

const show = computed(() =>
  (!props.start || props.start.getTime() <= now.value.getTime())
  && (!props.end || props.end.getTime() > now.value.getTime())
)
</script>

<template>
  <template v-if="show">
    <slot />
  </template>
</template>
